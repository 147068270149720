const cookieSettingButton = "#btnCookieSettings";
const cookieConsent = "#cookieConsent";

$(() => {
    initCookieClick();
})

const initCookieClick = () => {

    insertCookieInHead();

    const $cookieButton = $(cookieSettingButton);
    $cookieButton.on("click", (event: JQuery.Event) => {
        // @ts-ignore: External library
        CookieConsent.renew();
    });
}

const insertCookieInHead = () => {
    const script = document.createElement("script");
    script.id = "cookieConsent";
    script.src = "https://policy.app.cookieinformation.com/uc.js";
    script.setAttribute("data-culture", "DA");
    script.type = "text/javascript";
    script.setAttribute("defer", "");

    document.getElementsByTagName("head")[0].appendChild(script);
}